import React, {useState, useEffect, createContext, useContext, useMemo} from "react";
import DataGrid, {textEditor, SelectColumn} from "react-data-grid";

import {axiosInstance} from "../../../bricks/axios";
import {useAppData} from "../../../context/AppDataContext";
import {API} from "../../../config/Config";
import useTranslation from "../../../hooks/useTranslation";
import SelectEditor from "../../react-data-grid/editors/SelectEditor";
import moment from 'moment';
import DateEditor from "../../react-data-grid/editors/DateEditor";
import Loading from "../../../bricks/Loading";
import Forms from "../../../bricks/form/Forms";
import Modal from "../../../bricks/Modal";
import UserDetailModalContent from "./UserDetailModalContent";

import "./UserAdministration.scss";

const FilterContext = createContext();

function inputStopPropagation(event) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.stopPropagation();
    }
}

const UserAdministration = () => {
    const {t} = useTranslation();
    const {setError, setSuccess, selectedPeriod} = useAppData();
    const [showModal, setShowModal] = useState(false);
    const [rows, setRows] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [nationalityList, setNationalityList] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [maxTestPoints, setMaxTestPoints] = useState();
    const [courseMap, setCourseMap] = useState();
    const [statusList, setStatusList] = useState([]);
    const [filters, setFilters] = useState({name: '', surname: '', enabled: true});
    const [selectedUser, setSelectedUser] = useState();
    const [modalAction, setModalAction] = useState(null);
    const [courseOptions, setCourseOptions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {method, url} = API.loadUsersOverview;
                const {
                    data
                } = await axiosInstance[method](url, {params: {selectedPeriod}});
                const {
                    userList,
                    maxTestPoints,
                    nationalityList,
                    statusList,
                    courseList
                } = data;

                setCourseOptions(courseList.map(course => {
                    return {value: course._id, label: course.code};
                }));
                setCourseMap(courseList.reduce((acc, course) => {
                    acc[course._id] = course;
                    return acc;
                }, {}));
                setMaxTestPoints(maxTestPoints);
                setStatusList(statusList);
                setNationalityList(nationalityList);
                const tmpRows = userList.map((u, i) => ({...u, origIndex: i}));
                setRows(tmpRows);
                setFilteredRows(tmpRows);
                setIsLoading(false);
            } catch (error) {
                setError(error);
                setIsLoading(false);
            }
        };

        selectedPeriod && fetchData();
    }, [selectedPeriod]);


    useEffect(() => {
        if (rows) {
            const newFilteredRows = rows
                ?.filter(row => {
                    return Object.keys(filters).every((key) => {
                            if (key !== "enabled") {
                                if (typeof filters[key] === 'number') {
                                    return row[key] === filters[key];
                                } else if (filters[key]) {
                                    return row[key].toString().toLowerCase().includes(filters[key].toLowerCase());
                                }
                            }
                            return true;
                        }
                    )
                });
            setFilteredRows(newFilteredRows);
        }
    }, [rows, filters]);

    const handleFieldUpdate = async (updatedRow, {key}) => {
        try {
            const body = {[key]: updatedRow[key]};
            const {method, url} = API.updateUser(updatedRow._id);
            const {data} = await axiosInstance[method](url, body);
            // update rows
            setRows(prevRows => {
                const newRows = [...prevRows];
                newRows[updatedRow.origIndex] = {...newRows[updatedRow.origIndex], [key]: data[key]};
                return newRows;
            });
            setSuccess(<label>Uživatel <strong>{updatedRow.name} {updatedRow.surname}</strong> úspěšně
                aktualizován.</label>);
        } catch (e) {
            setError(e);
        }
    }

    const deleteTestResult = async (id) => {
        try {
            const {method, url} = API.deleteTestResult(id);
            await axiosInstance[method](url);
            return null;
        } catch (e) {
            throw new Error(`Failed to delete test result: ${e.message}`);
        }
    };

    const updateTestResult = async (id, body) => {
        try {
            const {method, url} = API.updateTestResult(id);
            const {data} = await axiosInstance[method](url, body);
            return data;
        } catch (e) {
            throw new Error(`Failed to update test result: ${e.message}`);
        }
    };

    const createTestResult = async (body) => {
        try {
            body.period = selectedPeriod;
            body.testType = 'sortingTest';
            const {method, url} = API.createTestResult;
            const {data} = await axiosInstance[method](url, body);
            return data;
        } catch (e) {
            throw new Error(`Failed to create test result: ${e.message}`);
        }
    };

    const handleTestFieldUpdate = async (updatedRow, {key}) => {
        try {
            let body = {[key]: updatedRow[key], user: updatedRow._id};

            if (updatedRow.testResult) {
                if (updatedRow[key] === "") {
                    // if testResult obj already exists and new value of test.result is false -> delete testResult object
                    await deleteTestResult(updatedRow.testResult._id);
                    body = null;
                } else {
                    // if testResult obj already exists -> update testResult obj
                    body = await updateTestResult(updatedRow.testResult._id, body);
                }
            } else if (updatedRow[key] !== "") {
                // if testResult obj doesn't exist and new value of test.result is true -> create one
                body = await createTestResult(body);
            }

            // update rows
            setRows(prevRows => {
                const newRows = [...prevRows];
                newRows[updatedRow.origIndex] = {...newRows[updatedRow.origIndex], testResult: body};
                return newRows;
            });

            setSuccess(<label>Test uživatele <strong>{updatedRow.name} {updatedRow.surname}</strong> úspěšně
                aktualizován.</label>);

        } catch (e) {
            setError(e);
        }
    };


    const handleCourseRegistrationUpdate = async (updatedRow, {key}) => {
        try {
            let course = updatedRow[key];
            // handle isAlternate
            if (key.match(/isAlternate|passed|course/) && course === "") {
                course = null;
            }

            let data;
            if (key === "course" && course === null) {
                // delete courseRegistration
                const {method, url} = API.deleteCourseRegByUser(updatedRow._id, selectedPeriod);
                await axiosInstance[method](url);

                // update rows
                setRows(prevRows => {
                    const newRows = [...prevRows];
                    newRows[updatedRow.origIndex] = {...newRows[updatedRow.origIndex], course: null};
                    return newRows;
                });
                setSuccess(<label>Registrace do kurzu
                    uživatele <strong>{updatedRow.name} {updatedRow.surname}</strong> úspěšně
                    smazána.</label>);
            } else {
                // update course registration
                const {method, url} = API.handleCourseRegByUser(updatedRow._id);
                const response = await axiosInstance[method](url, {period: selectedPeriod, course});
                data = response.data;

                // update rows
                setRows(prevRows => {
                    const newRows = [...prevRows];
                    newRows[updatedRow.origIndex] = {...newRows[updatedRow.origIndex], [key]: data[key]};
                    return newRows;
                });
                setSuccess(<label>Registrace do kurzu
                    uživatele <strong>{updatedRow.name} {updatedRow.surname}</strong> úspěšně
                    aktualizována.</label>);
            }
        } catch (e) {
            setError(e);
        }
    }

    const filterColumnClassName = 'filter-cell';

    const columns = useMemo(() => [
        SelectColumn,
        {
            key: "name",
            name: "Jméno",
            renderEditCell: textEditor,
            width: 170,
            frozen: true,
            editable: true,
            updateFn: handleFieldUpdate,
            headerCellClass: filterColumnClassName,
            renderCell: ({row}) => <label className="name"><Forms.DetailIcon
                onClick={() => handleModalOpen("detail", row._id)}/>{row.name}</label>,
            renderHeaderCell: (p) => (
                <FilterRenderer {...p}>
                    {({filters, ...rest}) => (
                        <input
                            {...rest}
                            value={filters[p.column.key]}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    [p.column.key]: e.target.value
                                })
                            }
                            onKeyDown={inputStopPropagation}
                        />
                    )}
                </FilterRenderer>
            )
        },
        {
            key: "surname",
            name: "Příjmení",
            renderEditCell: textEditor,
            width: 170,
            frozen: true,
            editable: true,
            updateFn: handleFieldUpdate,
            headerCellClass: filterColumnClassName,
            renderHeaderCell: (p) => (
                <FilterRenderer {...p}>
                    {({filters, ...rest}) => (
                        <input
                            {...rest}
                            value={filters[p.column.key]}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    [p.column.key]: e.target.value
                                })
                            }
                            onKeyDown={inputStopPropagation}
                        />
                    )}
                </FilterRenderer>
            )
        },
        {
            key: "email",
            name: "E-mail",
            width: 200,
            resizable: true,
            renderEditCell: textEditor,
            updateFn: handleFieldUpdate,
            editable: true,
            headerCellClass: filterColumnClassName,
            renderHeaderCell: (p) => (
                <FilterRenderer {...p}>
                    {({filters, ...rest}) => (
                        <input
                            {...rest}
                            value={filters[p.column.key]}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    [p.column.key]: e.target.value
                                })
                            }
                            onKeyDown={inputStopPropagation}
                        />
                    )}
                </FilterRenderer>
            )
        },
        {
            key: "mobile",
            name: "Mobil",
            width: 120,
            resizable: true,
            renderEditCell: textEditor,
            updateFn: handleFieldUpdate,
            editable: true,
            headerCellClass: filterColumnClassName,
            renderHeaderCell: (p) => (
                <FilterRenderer {...p}>
                    {({filters, ...rest}) => (
                        <input
                            {...rest}
                            value={filters[p.column.key]}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    [p.column.key]: e.target.value
                                })
                            }
                            onKeyDown={inputStopPropagation}
                        />
                    )}
                </FilterRenderer>
            )
        },
        {
            key: "nationality",
            name: "Národnost",
            width: 100,
            renderEditCell: (props) => <SelectEditor options={nationalityOptions} {...props}/>,
            updateFn: handleFieldUpdate
        },
        {
            key: "residenceStatus",
            name: "Status pobytu",
            width: 130,
            renderCell: ({row}) => t(row.residenceStatus),
            renderEditCell: (props) => <SelectEditor options={statusOptions} {...props}/>,
            updateFn: handleFieldUpdate
        },
        {
            key: "varSymbol",
            name: "Var. symbol",
            width: 90,
            headerCellClass: filterColumnClassName,
            renderHeaderCell: (p) => (
                <FilterRenderer {...p}>
                    {({filters, ...rest}) => (
                        <input
                            {...rest}
                            value={filters[p.column.key]}
                            onChange={(e) =>
                                setFilters({
                                    ...filters,
                                    [p.column.key]: e.target.value
                                })
                            }
                            onKeyDown={inputStopPropagation}
                        />
                    )}
                </FilterRenderer>
            )
        },
        {
            key: "dateOfBirth",
            name: "Datum narození",
            width: 120,
            renderCell: ({row}) => moment(row.dateOfBirth).format('DD.MM.YYYY'),
            renderEditCell: (props) => <DateEditor {...props}/>,
            updateFn: handleFieldUpdate
        },
        {
            key: "sex",
            name: "Pohlaví",
            width: 80,
            renderCell: ({row}) => t(row.sex),
            editable: true,
            renderEditCell: props => <SelectEditor options={sexOptions} {...props}/>,
            updateFn: handleFieldUpdate
        },
        {
            key: "course",
            name: "Kurz",
            width: 150,
            renderCell: ({row}) => courseMap[row.course]?.code,
            renderEditCell: props => <SelectEditor options={courseOptions} {...props} emptyOption/>,
            updateFn: handleCourseRegistrationUpdate // tato funkce není dodělaná
        },
        {
            key: "result",
            name: "Výsledek z testu",
            width: 150,
            renderCell: ({row}) => {
                return <div style={{display: "flex", alignItems: 'center', justifyContent: 'center'}}>
                    {
                        row.testResult ? (
                            <>
                                <progress max={100} value={row.testResult.result / maxTestPoints * 100}
                                          style={{inlineSize: 90}}/>
                                &nbsp;{Math.round(row.testResult.result / maxTestPoints * 100)}%
                            </>
                        ) : ''
                    }
                </div>;
            },
            editable: true,
            renderEditCell: props => <SelectEditor options={testResultOptions} {...props} emptyOption
                                                   value={props.row?.testResult?.result}/>,
            updateFn: handleTestFieldUpdate
        },
        {
            key: "note",
            name: "Poznámka",
            renderEditCell: textEditor,
            editable: true,
            updateFn: handleFieldUpdate
        }
    ].map(i => ({...i, resizable: true})), [maxTestPoints]);


    const FilterRenderer = ({tabIndex, column, children}) => {
        const filters = useContext(FilterContext);

        return (
            <>
                <div>{column.name}</div>
                {filters.enabled && <div>{children({tabIndex, filters})}</div>}
            </>
        );
    }

    // const sortRows = (initialRows, sortColumn, sortDirection) => {
    //     const comparer = (a, b) => {
    //         if (sortDirection === "ASC") {
    //             return a[sortColumn] > b[sortColumn] ? 1 : -1;
    //         } else if (sortDirection === "DESC") {
    //             return a[sortColumn] < b[sortColumn] ? 1 : -1;
    //         }
    //         return 0;
    //     };
    //
    //     if (sortColumn === 'course') {
    //         const regex = /ICP-(\d+)$/;
    //         return sortDirection === "NONE" ? initialRows : [...initialRows].sort((a, b) => {
    //             const aMatch = a.course.match(regex);
    //             const bMatch = b.course.match(regex);
    //
    //             if (aMatch && bMatch) {
    //                 const a_ = Number(aMatch[1]);
    //                 const b_ = Number(bMatch[1]);
    //                 return sortDirection === "ASC" ? a_ - b_ : b_ - a_;
    //             } else if (aMatch && !bMatch) {
    //                 return sortDirection === "ASC" ? -1 : 1;
    //             } else if (!aMatch && bMatch) {
    //                 return sortDirection === "ASC" ? 1 : -1;
    //             }
    //             return 0;
    //         });
    //     }
    //
    //     return sortDirection === "NONE" ? initialRows : [...initialRows].sort(comparer);
    // };

    const sexOptions = [
        {value: 'female', label: t('female')},
        {value: 'male', label: t('male')},
    ];
    const nationalityOptions = nationalityList.map(item => ({value: item.nationality, label: item.nationality}));
    const statusOptions = statusList.map(item => ({value: item.type, label: t(item.type)}));
    const testResultOptions = Array.from({length: maxTestPoints + 1}, (_, i) => ({label: i, value: i}));

    const renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else {
            const updateRow = (updatedRows, {column, indexes}) => {
                column.updateFn(updatedRows[indexes[0]], column, indexes[0]);
            }

            const rowKeyGetter = row => row._id;

            return (
                <>
                    <FilterContext.Provider value={filters}>
                        <DataGrid
                            className="rdg-light bordered"
                            style={{height: 700}}
                            rowKeyGetter={rowKeyGetter}
                            columns={columns}
                            rows={filteredRows}
                            selectedRows={selectedRows}
                            onSelectedRowsChange={setSelectedRows}
                            onRowsChange={updateRow}
                            headerRowHeight={filters.enabled ? 70 : undefined}
                        />
                    </FilterContext.Provider>
                    <div className="user-administration-button-group">
                        {/*<Forms.Button label="Vytisknout dokumenty uživatele" type="button" onClick={printUserCards}/>*/}
                        {/*<Button label="Export do CSV" type="button" onClick={ref => exportToCsv(ref)}/>*/}
                        {/*<Button label="Export pouze zapsané" type="button" onClick={ref => exportToCsv(ref, true)}/>*/}
                    </div>
                </>
            );
        }
    };

    const handleUpdateUser = (action, userData) => {
        const newRows = [...rows];
        if (action === "add") {
            newRows.push(userData);
        } else {
            const index = newRows.findIndex(user => user._id === userData._id);
            if (action === "delete") {
                newRows.splice(index, 1);
            } else {
                newRows[index] = {...newRows[index], ...userData};
            }
        }
        setRows(newRows);

        const newFilteredRows = newRows
            ?.filter(row => {
                return Object.keys(filters).every((key) => {
                        if (key !== "enabled") {
                            if (typeof filters[key] === 'number') {
                                return row[key] === filters[key];
                            } else if (filters[key]) {
                                return row[key].toString().toLowerCase().includes(filters[key].toLowerCase());
                            }
                        }
                        return true;
                    }
                )
            });
        setFilteredRows(newFilteredRows);
    }

    const handleModalOpen = (action, userId = null) => {
        setModalAction(action);
        setShowModal(true);
        setSelectedUser(userId);
    }

    const modalHeader = modalAction === "create" ? 'Vyvtvořit uživatele' : 'Detail uživatele';
    return (
        <div className="UserAdministration">
            <Modal size="xl" show={showModal} onHide={() => setShowModal(false)} header={modalHeader}>
                <UserDetailModalContent nationalityOptions={nationalityOptions} statusOptions={statusOptions}
                                        updateUser={handleUpdateUser}
                                        userId={modalAction === "detail" && selectedUser}/>
            </Modal>
            <div className="header">
                <h2>Uživatelé {!isLoading && ` celkem: ${rows.length}`}</h2>
                <div className="controls">
                    <Forms.AddIcon title="Vytvořit" onClick={() => handleModalOpen("create")}/>
                </div>
            </div>
            <div className="dataGrid">
                {renderContent()}
            </div>
        </div>
    );
};

export default UserAdministration;
