import React, {createContext, useContext, useEffect, useMemo, useRef, useState} from "react";
import DataGrid, {textEditor} from "react-data-grid";
import Forms from "../../../bricks/form/Forms";
import FileSaver from "file-saver";
import {useAppData} from "../../../context/AppDataContext";
import Loading from "../../../bricks/Loading";
import {API} from "../../../config/Config";
import {axiosInstance} from "../../../bricks/axios";
import SelectEditor from "../../react-data-grid/editors/SelectEditor";
import moment from "moment";
import {BoolFormatter} from "../../react-data-grid/formatters/BoolFormatter";
import BoolEditor from "../../react-data-grid/editors/BoolEditor";
import Modal from "../../../bricks/Modal";
import UserDetailModalContent from "../user/UserDetailModalContent";
import useTranslation from "../../../hooks/useTranslation";
import {exportToCsv} from "../../react-data-grid/export-utils";
import ActivateOtherTestForCourseModalBody from "./ActivateOtherTestForCourseModalBody";

import "./CourseRegistrationAdministration.scss";

const FilterContext = createContext();

function inputStopPropagation(event) {
    if (['ArrowLeft', 'ArrowRight'].includes(event.key)) {
        event.stopPropagation();
    }
}

const CourseRegistrationAdministration = () => {
    const {setError, selectedPeriod, setSuccess, period} = useAppData();
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = useState(true);
    const [rows, setRows] = useState();
    const [err, setErr] = useState();
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [filteredRows, setFilteredRows] = useState([]);
    const [filters, setFilters] = useState({name: '', varSymbol: '', enabled: true});
    const [courseList, setCourseList] = useState();
    const [selectedCourse, setSelectedCourse] = useState();
    const [courseOptions, setCourseOptions] = useState();
    const [courseMap, setCourseMap] = useState();
    const itemRefs = useRef([]);
    const [activeStyle, setActiveStyle] = useState({left: 0, width: 0});
    const [selectedUser, setSelectedUser] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showTestModal, setShowTestModal] = useState(false);
    const [nationalityOptions, setNationalityOptions] = useState();
    const [statusOptions, setStatusOptions] = useState();
    const [selectedTest, setSelectedTest] = useState({});
    const [maxTestPoints, setMaxTestPoints] = useState();

    useEffect(() => {
        const init = async () => {
            try {
                // courseRegistrations
                const {method, url} = API.loadCourseRegistrationData(selectedPeriod);
                const {data} = await axiosInstance[method](url);

                // courses
                const {method: courseMethod, url: courseUrl} = API.loadCoursesByPeriod(selectedPeriod);
                const {data: courses} = await axiosInstance[courseMethod](courseUrl);
                setCourseList(courses);
                setCourseOptions(courses.map(course => {
                    return {value: course._id, label: course.code};
                }));
                setCourseMap(courses.reduce((acc, course) => {
                    acc[course._id] = course;
                    return acc;
                }, {}));

                // load testResults
                const {method: m2, url: u2} = API.loadOtherTestResultsByPeriod(selectedPeriod);
                const {data: resultList} = await axiosInstance[m2](u2);
                const otherTestResultMap = resultList.reduce((acc, testResult) => {
                    acc[testResult.user] = testResult;
                    return acc;
                }, {});

                // load nationality list
                const {method: m1, url: u1} = API.loadNationalities;
                const {data: nationalityList} = await axiosInstance[m1](u1);
                const nationalityOptions = nationalityList.map(item => ({
                    value: item.nationality,
                    label: item.nationality
                }));
                setNationalityOptions(nationalityOptions);

                // load status list
                const {method: m3, url: u3} = API.loadStatuses;
                const {data: statusList} = await axiosInstance[m3](u3);
                const statusOptions = statusList.map(item => ({value: item.type, label: t(item.type)}));
                setStatusOptions(statusOptions);

                // chceme flat strukutu kvůli filtrování
                const tmp = data.map((row, i) => {
                    row.name = `${row.user.surname} ${row.user.name}`;
                    row.varSymbol = `${row.user.varSymbol}`;
                    row.email = row.user.email;
                    row.origIndex = i;
                    row.otherTestResult = otherTestResultMap?.[row.user._id];
                    return row;
                })
                setRows(tmp);
                setFilteredRows(tmp);
            } catch (error) {
                setError(error);
            } finally {
                setIsLoading(false);
            }
        };

        if (selectedPeriod) {
            init();
        }

    }, [selectedPeriod]);

    function sortItems(items) {
        return items.sort((a, b) => {
            // Porovnání hodnot isAlternate
            if (a.isAlternate === false && b.isAlternate !== false) return -1;
            if (b.isAlternate === false && a.isAlternate !== false) return 1;
            if (a.isAlternate === true && b.isAlternate !== true && b.isAlternate !== false) return -1;
            if (b.isAlternate === true && a.isAlternate !== true && a.isAlternate !== false) return 1;
            if (a.isAlternate === null && b.isAlternate !== null) return 1;
            if (b.isAlternate === null && a.isAlternate !== null) return -1;

            // Pokud je isAlternate stejný, seřadit podle názvu (name)
            return a.name.localeCompare(b.name);
        }).map((row, index) => ({...row, n: index + 1}));
    }

    const handleOpenDetailModal = row => {
        setSelectedUser(row.user._id);
        setShowModal(true);
    }
    const updateActiveBackground = () => {
        const index = courseList.findIndex(c => c._id === selectedCourse);
        if (index >= 0 && itemRefs.current[index]) {
            const item = itemRefs.current[index];
            setActiveStyle({
                left: item.offsetLeft,
                top: item.offsetTop,
                width: item.offsetWidth,
            });
        }
    };

    useEffect(() => {
        courseList && updateActiveBackground();
        const loadTestQuestions = async () => {
            if (courseMap?.[selectedCourse]?.testConfig) {
                const testId = courseMap[selectedCourse]?.testConfig[0]?.test;
                if (testId) {
                    // load testQuestion
                    const {method, url} = API.loadTestQuestionsByPeriodAndType(selectedPeriod, 'otherTest', testId);
                    const {data} = await axiosInstance[method](url);
                    setMaxTestPoints(data.length);
                }
            }
        }
        loadTestQuestions();
    }, [selectedCourse]);

    const _renderCourseLinks = () => {
        if (courseList) {
            const courseLinks = courseList.sort((a, b) => {
                if (a.code.match(/ICP-(\d+)$/) && b.code.match(/ICP-(\d+)$/)) {
                    return a.code.match(/ICP-(\d+)$/)[1] - b.code.match(/ICP-(\d+)$/)[1];
                } else {
                    return a.code - b.code;
                }
            }).map((course, i) =>
                <label className={course._id === selectedCourse ? 'selected' : ''}
                       ref={(el) => (itemRefs.current[i] = el)}
                       onClick={() => setSelectedCourse(course._id)}>{course.code}</label>
            );
            return (
                <div className="course-links">{React.Children.toArray(courseLinks)}</div>
            );
        }
    }

    const handleFieldUpdate = async (updatedRow, {key}) => {
        try {
            let value = updatedRow[key];
            // handle bool attributes
            if (key.match(/isAlternate|passed|course/) && value === "") {
                value = null;
            }

            // delete courseRegistration
            let data;
            if (key === "course" && value === null) {
                const {method, url} = API.deleteCourseRegistration(updatedRow._id);
                const response = await axiosInstance[method](url);
                data = response.data;

                // update rows
                setRows(prevRows => prevRows.filter(row => row._id !== updatedRow._id));
                setSuccess(<label>Registrace do kurzu
                    uživatele <strong>{updatedRow.name} {updatedRow.surname}</strong> úspěšně
                    smazána.</label>);
            } else {
                // update course registration
                const body = {[key]: value};
                const {method, url} = API.updateCourseRegistration(updatedRow._id);
                const response = await axiosInstance[method](url, body);
                data = response.data;

                // update rows
                setRows(prevRows => {
                    const newRows = [...prevRows];
                    newRows[updatedRow.origIndex] = {...newRows[updatedRow.origIndex], ...data};
                    return newRows;
                });
                setSuccess(<label>Registrace do kurzu
                    uživatele <strong>{updatedRow.name} {updatedRow.surname}</strong> úspěšně
                    aktualizována.</label>);
            }
        } catch (e) {
            setError(e);
        }
    }

    const filterColumnClassName = 'filter-cell';

    const FilterRenderer = ({tabIndex, column, children}) => {
        const filters = useContext(FilterContext);

        return (
            <>
                <div>{column.name}</div>
                {filters?.enabled && <div>{children({tabIndex, filters})}</div>}
            </>
        );
    }

    const deleteTestResult = async (id) => {
        try {
            const {method, url} = API.deleteTestResult(id);
            await axiosInstance[method](url);
            return null;
        } catch (e) {
            throw new Error(`Failed to delete test result: ${e.message}`);
        }
    };

    const updateTestResult = async (id, body) => {
        try {
            const {method, url} = API.updateTestResult(id);
            const {data} = await axiosInstance[method](url, body);
            return data;
        } catch (e) {
            throw new Error(`Failed to update test result: ${e.message}`);
        }
    };

    const handleTestFieldUpdate = async (updatedRow, {key}) => {
        try {
            let body = {[key]: updatedRow[key]};

            if (updatedRow.otherTestResult) {
                if (updatedRow[key] === "") {
                    // if testResult obj already exists and new value of test.result is false -> delete testResult object
                    await deleteTestResult(updatedRow.otherTestResult._id);
                    body = null;
                } else {
                    // if testResult obj already exists -> update testResult obj
                    body = await updateTestResult(updatedRow.otherTestResult._id, body);
                }
            } else if (updatedRow[key] !== "") {
                // if testResult obj doesn't exist and new value of test.result is true -> create one
                alert("Výsledek z testu zatím nelze vytvořit.");
            }

            // update rows
            setRows(prevRows => {
                const newRows = [...prevRows];
                newRows[updatedRow.origIndex] = {...newRows[updatedRow.origIndex], otherTestResult: body};
                if (body.hasOwnProperty("passed")) {
                    newRows[updatedRow.origIndex].passed = body.passed;
                }
                if (body.hasOwnProperty("paymentRefunded")) {
                    newRows[updatedRow.origIndex].paymentRefunded = body.paymentRefunded;
                }
                return newRows;
            });

            setSuccess(<label>Test uživatele <strong>{updatedRow.name} {updatedRow.surname}</strong> úspěšně
                aktualizován.</label>);

        } catch (e) {
            setError(e);
        }
    };

    const columns = useMemo(() => {
        if (courseList) {
            const testResultOptions = Array.from({length: maxTestPoints + 1}, (_, i) => ({label: i, value: i}));
            const cols = [
                {
                    key: "n",
                    name: "#",
                    frozen: true,
                    width: 70,
                },
                {
                    key: "name",
                    name: "Jméno",
                    frozen: true,
                    width: 200,
                    renderCell: ({row}) => <label className="name"><Forms.DetailIcon
                        onClick={() => handleOpenDetailModal(row)}/>{row.name}</label>,
                    headerCellClass: filterColumnClassName,
                    renderHeaderCell: (p) => <FilterRenderer {...p}>
                        {({filters, ...rest}) => (
                            <input
                                {...rest}
                                value={filters[p.column.key]}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        [p.column.key]: e.target.value
                                    })
                                }
                                onKeyDown={inputStopPropagation}
                            />
                        )}
                    </FilterRenderer>
                },
                {
                    key: "varSymbol",
                    name: "Var. symbol",
                    width: 100,
                    headerCellClass: filterColumnClassName,
                    renderHeaderCell: (p) => <FilterRenderer {...p}>
                        {({filters, ...rest}) => (
                            <input
                                {...rest}
                                style={{width: "90px"}}
                                value={filters[p.column.key]}
                                onChange={(e) =>
                                    setFilters({
                                        ...filters,
                                        [p.column.key]: e.target.value
                                    })
                                }
                                onKeyDown={inputStopPropagation}
                            />
                        )}
                    </FilterRenderer>
                },
                {
                    key: "createdAt",
                    name: "Zapsán",
                    renderCell: ({row}) => moment(row.createdAt).format('DD.MM.YYYY'),
                    width: 100
                },
                {
                    key: "course",
                    name: "Kurz",
                    renderCell: ({row}) => courseMap[row.course]?.code,
                    renderEditCell: props => <SelectEditor options={courseOptions} {...props} emptyOption/>,
                    updateFn: handleFieldUpdate,
                    width: 150
                },
                {
                    key: "isAlternate",
                    name: "Náhradník",
                    renderCell: BoolFormatter,
                    renderEditCell: props => <BoolEditor {...props} emptyOption/>,
                    updateFn: handleFieldUpdate,
                    width: 100
                },
                {
                    key: "passed",
                    name: "Prošel",
                    renderCell: BoolFormatter,
                    width: 100
                },
                {
                    key: "attendanceFulfilled",
                    name: "Splněna docházka",
                    renderCell: BoolFormatter,
                    renderEditCell: props => <BoolEditor {...props} emptyOption/>,
                    updateFn: handleFieldUpdate,
                    width: 100
                },
                {
                    key: "paymentCredited",
                    name: "Platba přijata",
                    renderCell: BoolFormatter,
                    width: 80
                },
                {
                    key: "paymentRefunded",
                    name: "Platba navrácena",
                    renderCell: BoolFormatter,
                    width: 80
                },
                {
                    key: "refundInCash",
                    name: "Vracet v hotovosti",
                    renderEditCell: props => <BoolEditor {...props} emptyOption/>,
                    updateFn: handleFieldUpdate,
                    renderCell: BoolFormatter,
                    width: 100
                },
                {
                    key: "returnBankAccount",
                    name: "Účet pro navrácení platby",
                    renderEditCell: textEditor,
                    updateFn: handleFieldUpdate,
                    width: 200,
                    editable: (row) => !row.refundInCash
                },
                {
                    key: "certificateDownloadDate",
                    name: "Stažení certifikátu",
                    renderCell: ({certificateDownloadDate}) => certificateDownloadDate ? moment(certificateDownloadDate).format('DD.MM.YYYY') : '',
                    width: 150
                }
            ].map(r => ({...r, resizable: true}));
            // cols
            cols.splice(8, 0, {
                key: "result",
                name: "Výsledek z testu",
                width: 150,
                renderCell({row}) {
                    return <div style={{display: "flex", alignItems: 'center', justifyContent: 'center'}}>
                        {
                            row.otherTestResult ? (
                                <>
                                    <progress max={100} value={row.otherTestResult.resultPercentage}
                                              style={{inlineSize: 90}}/>
                                    &nbsp;{Math.round(row.otherTestResult.resultPercentage)}%
                                </>
                            ) : '-'
                        }
                    </div>;
                },
                editable: true,
                renderEditCell: props => <SelectEditor options={testResultOptions} {...props} emptyOption
                                                       value={props.row?.otherTestResult?.result}/>,
                updateFn: handleTestFieldUpdate
            });
            return cols;
        }
    }, [courseList, maxTestPoints]);

    useEffect(() => {
        if (rows) {
            let newFilteredRows = rows
                ?.filter(row => selectedCourse ? row.course === selectedCourse : true)
                .filter(row => {
                    return Object.keys(filters).every((key) => {
                            return key !== "enabled" && filters[key] ? row[key].toLowerCase().includes(filters[key].toLowerCase()) : true;
                        }
                    )
                });
            newFilteredRows = sortItems(newFilteredRows);
            setFilteredRows(newFilteredRows);
        }
    }, [rows, filters, selectedCourse]);

    let gridElement;
    const renderTable = () => {
        const updateRow = (updatedRows, {column, indexes}) => {
            column.updateFn(updatedRows[indexes[0]], column, indexes[0]);
        }

        const rowKeyGetter = row => row._id;
        gridElement = <DataGrid
            className="rdg-light bordered"
            style={{height: 700}}
            rowKeyGetter={rowKeyGetter}
            columns={columns}
            rows={filteredRows}
            selectedRows={selectedRows}
            onSelectedRowsChange={setSelectedRows}
            onRowsChange={updateRow}
            headerRowHeight={filters.enabled ? 70 : undefined}
            rowClass={row => `isAlternate_${JSON.stringify(row.isAlternate)} payment_${JSON.stringify(row.paymentCredited)}`}
        />;
        return (
            <FilterContext.Provider value={filters}>
                {gridElement}
            </FilterContext.Provider>
        );
    }

    const handleDrawCourseRegistrations = async () => {
        try {
            const {method, url} = API.drawCourse(selectedCourse);
            const {data} = await axiosInstance[method](url);
            const {course, alternateIds, notAlternateIds} = data;
            // update rows
            setRows(rows => rows.map(row => {
                if (alternateIds.indexOf(row._id) !== -1) row.isAlternate = true;
                if (notAlternateIds.indexOf(row._id) !== -1) row.isAlternate = false;
                return row;
            }))
            // update courseList and courseMap
            setCourseList(c => {
                if (c._id === course._id) c.drawn = true;
                return c;
            });
            setCourseMap(courseMap => {
                const newMap = {...courseMap};
                newMap[course._id] = newMap[course._id].dranw = true;
                return newMap;
            });
            setSuccess(`Kurz ${courseMap[selectedCourse]} byl úspěšně slosován`);
        } catch (e) {
            console.log(e);
            setError(e);
        }
    }

    const generateXMLPayment = async () => {
        const {method, url} = API.generateXmlPayment(selectedCourse);
        try {
            const {data} = await axiosInstance[method](url, {responseType: "blob"});
            const blob = new Blob([data], {type: 'application/xml'}); // Konverze řetězce na blob

            const courseCode = courseMap[selectedCourse].code.replaceAll("/", '-');
            FileSaver.saveAs(blob, `platebni-prikaz_${courseCode}.xml`);
        } catch (e) {
            setError(e);
        }
    }

    const exportOtherTestResults = async () => {
        try {
            const {method, url} = API.exportTestResults(selectedPeriod, 'sortingTest');
            const {data} = await axiosInstance[method](url, {responseType: "blob"});

            const now = new Date();
            const fileName = `vysledky-testu-${now.getDate()}.${now.getMonth() + 1}.${now.getFullYear()}_${now.getHours()}:${now.getMinutes()}.csv`;
            FileSaver.saveAs(data, fileName);
        } catch (error) {
            setError(error);
        }
    }

    const exportCourseRegistrations = async (isAlternateStateList = [false], onlyPaid = false) => {
        try {
            const {method, url} = API.exportCourseRegistration;
            const {data} = await axiosInstance[method](url, {
                responseType: "blob",
                period: selectedPeriod,
                course: selectedCourse,
                onlyPaid,
                isAlternateStateList
            });

            // Automatické stažení souboru
            const link = document.createElement('a');
            link.href = data.filePath;
            link.download = `course-reg-${selectedCourse ? courseMap[selectedCourse].code : 'all'}.xlsx`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.log(error);
            setError(error);
        }
    }

    const renderCourseInfo = () => {
        if (selectedCourse) {
            return courseMap[selectedCourse].drawn ? <p>Kurz je SLOSOVANÝ</p> : '';
        }
    }

    const downloadXLSXOverview = async () => {
        try {
            const {method, url} = API.downloadXlsxPaymentOverview(selectedCourse);
            const {data} = await axiosInstance[method](url, {responseType: "blob"});
            const courseCode = courseMap[selectedCourse].code.replaceAll("/", '-');
            FileSaver.saveAs(data, `preheled_${courseCode}.xlsx`);
        } catch (error) {
            setError(error);
        }
    }

    const isCourseDrawn = selectedCourse && courseMap[selectedCourse].drawn;

    const handleCourseUpdate = (updatedCourse) => {
        setCourseMap(prevMap => ({...prevMap, [updatedCourse._id]: updatedCourse}));
        setCourseList(prevList => prevList.map(course => course._id === updatedCourse._id ? updatedCourse : course));
    };

    const editTest = (test) => {
        setSelectedTest(test);
        setShowTestModal(true);
    }

    const deleteTest = async (testId) => {
        if (window.confirm('Opravdu chcete smazat test?')) {
            const course = courseMap[selectedCourse];
            course.testConfig = course.testConfig.filter(test => test._id !== testId);

            const {method, url} = API.updateCourse(course._id);
            const {data} = await axiosInstance[method](url, course);
            handleCourseUpdate(data);
            setSuccess("Test byl smazán");
        }
    }

    const _renderCourseTests = (testConfig) => {
        const otherTestMap = period?.otherTestList?.reduce((acc, curVal) => {
            acc[curVal._id] = curVal.name;
            return acc;
        }, {});

        return (
            <table className="otherTest">
                <thead>
                <tr>
                    <th>Název testu</th>
                    <th>Sada otázek</th>
                    <th>Začátek</th>
                    <th>Konec</th>
                    <th>Děkovná hláška</th>
                </tr>
                </thead>
                <tbody>
                {testConfig.map((test, index) => (
                    <tr key={index}>
                        <td>{test.customName}</td>
                        <td>{otherTestMap[test.test]}</td>
                        <td>{moment(test.startDate).format('DD.MM.yyyy HH:mm')}</td>
                        <td>{moment(test.endDate).format('DD.MM.yyyy HH:mm')}</td>
                        <td>{test.customMessageTestCompleted}</td>
                        <td>
                            <button onClick={() => editTest(test)}>Edit</button>
                            <button onClick={() => deleteTest(test._id)}>Delete</button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        );
    }

    const renderContent = () => {
        if (isLoading) {
            return <Loading/>;
        } else if (err) {
            setError(err);
            return null;
        } else {
            return (
                <div className="CourseRegistrationAdministration">
                    <Modal show={showTestModal} onHide={() => setShowTestModal(false)} header="Zpřístupnění testu">
                        <ActivateOtherTestForCourseModalBody course={courseMap[selectedCourse]}
                                                             onSubmit={handleCourseUpdate}
                                                             existingTestData={selectedTest}/>
                    </Modal>
                    <Modal size="xl" show={showModal} onHide={() => setShowModal(false)} header={`Detail uživatele`}>
                        <UserDetailModalContent userId={selectedUser} nationalityOptions={nationalityOptions}
                                                statusOptions={statusOptions}/>
                    </Modal>
                    <h2>Registrace do kurzů</h2>
                    <div className="submenu">
                        <div className="active-background" style={activeStyle}/>
                        {_renderCourseLinks()}
                    </div>
                    <div className="additional-info">
                        {renderCourseInfo()}
                    </div>
                    <div className="controls">
                        <Forms.Button label="Exportovat výsledky testů" type="button" onClick={exportOtherTestResults}/>
                        {selectedCourse && <Forms.Button label="Exportovat zapsané registrace" type="button"
                                                         onClick={() => exportCourseRegistrations()}/>}
                        {!selectedCourse &&
                            <Forms.Button label="Exportovat zapsané a zaplacené registrace" type="button"
                                          onClick={() => exportCourseRegistrations([false], true)}/>}
                        {selectedCourse && <Forms.Button label="Exportovat všechny registrace" type="button"
                                                         onClick={() => exportCourseRegistrations([true, false, undefined])}/>}
                        {selectedCourse && !isCourseDrawn &&
                            <Forms.Button label="Slosovat" type="button" onClick={handleDrawCourseRegistrations}/>}
                        {isCourseDrawn && <Forms.Button label="Stáhnout platební příkaz v XML" type="button"
                                                        onClick={generateXMLPayment}/>}
                        {isCourseDrawn && <Forms.Button label="Stáhnout platební příkaz v XLSX" type="button"
                                                        onClick={downloadXLSXOverview}/>}
                        <Forms.Button label="Stáhnout přehled (CSV)" disabled={isLoading}
                                      onClick={() => exportToCsv(gridElement, 'course-reg.csv')}/>
                    </div>
                    <div className="agenda row">
                        <div className="isAlternate_false payment_true col-md-4">Zapsaný - platba přijate</div>
                        <div className="isAlternate_false payment_false col-md-4">Zapsaný - platba nepřijata</div>
                        <div className="isAlternate_true col-md-4">Náhradník</div>
                    </div>
                    {/*{_getSelectedCourse()}*/}
                    {/*{_getDrawnAndInformButton()}*/}
                    {/*{_renderExportSection()}*/}
                    {renderTable()}
                    {selectedCourse && <Forms.Button label="Zpřístupnit test" onClick={() => editTest(null)}/>}
                    {_renderCourseTests(courseMap?.[selectedCourse]?.testConfig || [])}
                </div>
            );
        }
    }

    return renderContent();
}
export default CourseRegistrationAdministration;
